export const environment = {
  production: true,
  SW:true,

  API_DOMAINS: {
    SISTEMAS: 'https://serv.sca.oticsofofa.cl/servicio/sistemas',
    PROVIDERS: 'https://serv.sca.oticsofofa.cl/servicio/providers',
    USERPOOL: 'https://serv.sca.oticsofofa.cl/servicio/userpool',
    USUARIOS: 'https://serv.sca.oticsofofa.cl/servicio/usuarios',
    ROLES: 'https://serv.sca.oticsofofa.cl/servicio/roles',

    CLIENTES: 'https://serv.sca.oticsofofa.cl/servicio2/clientes',
    USRPAS: 'https://serv.sca.oticsofofa.cl/servicio2/user/pas'
  },

  REGION: 'us-east-1',
  USER_POOL_ID: 'us-east-1_k6Pf6gdwX',
  CLIENT_ID: '7g7r4fdfg2rj7u1bvak5h4ajvl',
  URL_AUTH: 'https://sca.oticsofofa.cl/auth'
};
